import React, {Component} from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'

class NavBar extends Component {
    render(){
        return (
                <Row className="navbarContainer sticky-top">
                    <div class="mobile-menu-icon" onclick="toggleMobileMenu()">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                    <nav role="navigation" className="navbarList">
                        <a href="#root" className="navbarLink">DOMOV</a>
                        <a href="#news" className="navbarLink">NOVICE</a>
                        <a href="#about" className="navbarLink">O DRUŠTVU</a>
                        <a href="#sponsors" className="navbarLink">PARTNERJI</a>
                        <a href="#contact" className="navbarLink">KONTAKT</a>
                    </nav>
                </Row>
        )
    }
}

export default NavBar;